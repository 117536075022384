
<template>
<div class="card flex flex-col gap-2">
  <h1>VRP Solution</h1>

  <pre>{{ jobs }}</pre>

  <div class="flex flex-row gap-2 flex-wrap items-center">
    <UISelectMinMax
      :min="1"
      :max="50"
      v-model="aantalChauffeurs"
      label="Aantal chauffeurs"
      class="w-32"
    />
    <UISelectMinMax
      :min="1"
      :max="Math.max(jobs.length, 1)"
      v-model="variant"
      label="Variant"
      class="w-32"
    />
  </div>

  <div class="flex flex-row gap-2 flex-wrap items-center">
    <UISelectMinMax
      v-for="(wagen, index) in wagens"
      :key="`${index}-${wagen.passagiers}`"
      :min="0"
      :max="50"
      v-model="wagens[index].passagiers"
      :label="`Wagens ${wagen.passagiers} passagiers`"
      class="w-40"
    />
  </div>

  <hr class="my-4">

  <UILoading v-if="loading"></UILoading>
  <div v-else-if="missingGeo" class="bg-red-100 border border-red-300 rounded-sm p-4">
    Er missen geolocatie data voor enkele adressen, VRP kan niet worden berekend.
  </div>
  <div v-else class="grid gap-2 grid-cols-3">
    <div class="card flex flex-col gap-2" v-for="(item, index) in solution" :key="`${index}-${item.planning.map(el => el.id).join('_')}`">
      <div class="flex flex-row justify-between items-center">
        <h2>Chauffeur #{{ index + 1 }}</h2>
        <span class="label-time inline-flex gap-1"><i class="fas fa-shuttle-van text-base"></i>{{ item.wagen_passagiers }}</span>
      </div>

      <div v-for="(job, index) in item.planning" :key="`${index}-${job.id}`" class="flex flex-col gap-2 card">
        <div class="flex flex-row justify-between items-center">
          <span class="label-time inline">{{ timeString(job.datum) }}</span>
          <label class="label-info ml-0 text-xs">
            <i class="fas fa-users text-base"></i> {{ job.personen || '?' }}
          </label>
        </div>
        <GoogleAdressenVisueel class="card gray" :adressen="job.locations.map(el => el.adres)" />
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import clone from 'just-clone'
import { onMounted, inject, ref, computed, defineAsyncComponent, watch } from 'vue'
import { useRoute } from 'vue-router'

import { timeString } from '@/functions/formatDate'

import UILoading from '@/components/UI/Loading.vue'
import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
const GoogleAdressenVisueel = defineAsyncComponent(() => import('@/components/Google/AdressenVisueel.vue'))

const axios = inject('axios')
const query = computed(() => useRoute().query || {})

const aantalChauffeurs = ref(8)
const wagens = ref([
  { passagiers: 8, aantal: 8 },
  { passagiers: 7, aantal: 2 },
  { passagiers: 6, aantal: 1 },
  { passagiers: 4, aantal: 1 },
  { passagiers: 3, aantal: 0 },
  { passagiers: 2, aantal: 2 },
])

const jobs = ref([])
const solution = ref([])
const loading = ref(false)
const variant = ref(1)

const missingGeo = computed(() => false && !!jobs.value.find(job => job?.locations.find(loc => !loc.lat || !loc.lng)))

const recalculate = () => {
  solution.value = []
  loading.value = true
  setTimeout(() => {
    solution.value = calculateSolution()
    loading.value = false
  }, 1000)
}

onMounted(async () => {
  const { data } = await axios.get(`/api/dashboard/automatisatie/vrp-self?date=${query.value.date}`)
  jobs.value = data.jobs || []
  watch([aantalChauffeurs, wagens, variant], () => recalculate())
  recalculate()
})

const SECONDEN_PER_KILOMETER = 82.145871
const SECONDEN_PER_METER = SECONDEN_PER_KILOMETER / 1000

const calculateDistanceInMeters = (p1, p2) => haversine(p1, p2, { unit: 'meter' })

const calculateSolution = () => {
  let clonedJobs = clone(jobs.value)

  const clonedVehicles = clone(wagens.value)
  const vehiclesCount = clonedVehicles.reduce((acc, { aantal }) => acc + aantal, 0)
  const chauffeurs = Math.min(vehiclesCount, Math.max(aantalChauffeurs.value, 4))

  const solution = []

  if (clonedJobs.length === 0) {
    return solution
  }

  for (let chauffeur = 1; chauffeur <= chauffeurs; chauffeur++) {
    if (!clonedJobs.length) break

    const wagen_passagiers = clonedVehicles[0].passagiers
    let werktijd_seconded = 0

    const planning = []

    let previousJobs = null

    for (const job of clonedJobs) {
      let secondenTotJob = 0
      let currentJob = null

      if (!planning.length) {
        if (chauffeur === 1 && job !== clonedJobs[variant.value - 1]) continue
        currentJob = job
      } else {
        previousJobs = planning[planning.length - 1]

        const metersTotJob = calculateDistanceInMeters(
          previousJobs.locations[previousJobs.locations.length - 1],
          job.locations[job.locations.length - 1],
        )
        console.log({ metersTotJob })
        secondenTotJob = SECONDEN_PER_METER * metersTotJob

        const wouldArribeBy = new Date(new Date(job.datum).getTime() + ((previousJobs.seconden + secondenTotJob) * 1.15 / 1000)).getTime()
        console.log({
          prev: previousJobs.locations[previousJobs.locations.length - 1],
          curr: job.locations[job.locations.length - 1],
          metersTotJob,
          secondenTotJob,
          wouldArribeBy: new Date(wouldArribeBy),
          job: new Date(job.datum),
        })
        if (wouldArribeBy > new Date(job.datum).getTime()) continue
        currentJob = job
      }

      if (!currentJob) {
        // Nothing
      }

      currentJob.planning_passagiers -= wagen_passagiers
      if (currentJob.planning_passagiers <= 0) {
        clonedJobs = clonedJobs.filter(job => job !== currentJob)
      }

      planning.push({ ...currentJob, planning_passagiers: undefined })
      werktijd_seconded += secondenTotJob + currentJob.seconden

      if (werktijd_seconded > 25200000) {
        // Nothing
      }
    }

    solution.push({ planning, wagen_passagiers: clonedVehicles[0].passagiers })

    // Remove current wagen
    clonedVehicles[0].aantal--
    if (clonedVehicles[0].aantal === 0) {
      clonedVehicles.shift()
    }
  }

  return solution
}

// --- Haversine formula ---
const RADII = {
  km:    6371,
  mile:  3960,
  meter: 6371000,
  nmi:   3440,
}

// convert to radians
const toRad = (num) => num * Math.PI / 180

// convert coordinates to standard format based on the passed format option
const convertCoordinates = (format, coordinates) => {
  switch (format) {
  case '[lat,lon]':
    return { latitude: coordinates[0], longitude: coordinates[1] }
  case '[lon,lat]':
    return { latitude: coordinates[1], longitude: coordinates[0] }
  case '{lon,lat}':
    return { latitude: coordinates.lat, longitude: coordinates.lng || coordinates.lon }
  case '{lat,lng}':
    return { latitude: coordinates.lat, longitude: coordinates.lng || coordinates.lon }
  case 'geojson':
    return { latitude: coordinates.geometry.coordinates[1], longitude: coordinates.geometry.coordinates[0] }
  default:
    if (coordinates.lat) {
      return { latitude: coordinates.lat, longitude: coordinates.lng || coordinates.lon }
    }
    if (Array.isArray(coordinates)) {
      return { latitude: coordinates[0], longitude: coordinates[1] }
    }
    return coordinates
  }
}

const haversine = (startCoordinates, endCoordinates, options) => {
  options   = options || {}

  console.log({ startCoordinates, endCoordinates, options })
  const R = options.unit in RADII
    ? RADII[options.unit]
    : RADII.km

  const start = convertCoordinates(options.format, startCoordinates)
  const end = convertCoordinates(options.format, endCoordinates)

  const dLat = toRad(end.latitude - start.latitude)
  const dLon = toRad(end.longitude - start.longitude)
  const lat1 = toRad(start.latitude)
  const lat2 = toRad(end.latitude)

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

  if (options.threshold) {
    return options.threshold > (R * c)
  }

  return R * c
}
</script>
